<template>
  <LandingPageLayout>
    <section class="white-section">
      <div class="container py-5">
        <div class="row ai-center gap-5">
          <div class="col-md-6 stagger__in-ease-right--1 order-1 order-md-2">
            <img class="w-100" src="/img/hire-talent.png" alt="" />
          </div>
          <div
            class="col-md-6 p-2 p-md-0 stagger__in-ease-left--1 order-2 order-md-1"
          >
            <h2 class="h2 mt-2">
              Find <span class="simple"> top tech talent </span> and build a
              great team
            </h2>
            <strong class="strong mt-2 block">
              Tech talent for everyone, and for all industries
            </strong>
            <p class="p mt-2">
              Our algorithms find only the right candidates for your job
              requirements in any industry. Find your perfect fit while saving
              time and money. Contact us today
            </p>
          </div>
        </div>
      </div>
    </section>
    <section class="gray-section py-8 px-1 px-md-0">
      <div class="container">
        <h3 class="text-center h3">Qualified candidates, actively looking</h3>

        <p class="p mt-2 text-center" style="max-width: 549px; margin: auto">
          Search through a pool of highly qualified candidates. Build
          future-ready tech teams using our suite of tools for technical
          recruitment.
        </p>

        <div
          class="mt-6"
          :style="{
            width: '100%',
            maxWidth: '800px',
            marginLeft: 'auto',
            marginRight: 'auto'
          }"
        >
          <h6 class="mt-6">How it Works!</h6>
          <hr class="nice-hr mt-2" />
          <!-- <TabbedLink :links="tabbedLinks" class="mt-2"></TabbedLink> -->
          <TheTab :tabs="tabs" v-model="activeTab" class="mt-2"></TheTab>

          <div v-if="activeTab === 'Training'" class="py-1">
            <p class="p mt-2" style="color: #667085">
              We’ll handle the entire hiring process for you as explained above.
            </p>
            <p class="p mt-1" style="color: #101828">
              Custom training courses to suit your needs
            </p>
          </div>

          <div class="tab-list mt-2">
            <div class="tab-list__item" v-for="(item, i) in activeList">
              <div class="flex">
                <div class="percent">
                  <PercentageCircle
                    :percent="Math.floor(((i + 1) / activeList.length) * 100)"
                    active-color="1AA7EC"
                  />
                </div>
                <div class="flex-1 ml-2">
                  <div class="tab-list__title">
                    {{ item.title }}
                  </div>

                  <p class="tab-list__description mt-1" v-if="item.description">
                    {{ item.description }}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <DarkSection></DarkSection>
  </LandingPageLayout>
</template>

<script>
import LandingPageLayout from "@/layouts/LandingPageLayout";
import TheTalent from "../components/TheTalent.vue";
import TheTab from "../components/TheTab.vue";
import PercentageCircle from "vue-css-percentage-circle";
import DarkSection from "./sections/DarkSection.vue";

export default {
  name: "LandingPage",
  components: {
    LandingPageLayout,
    TheTalent,
    TheTab,
    PercentageCircle,
    DarkSection
  },
  data() {
    return {
      formData: {},
      activeSlide: 1,
      slidesLength: 2,
      tabs: ["Recruitment", "Outsourcing", "Training"],
      activeTab: "Recruitment",
      talentAvatars: [
        "/img/avatars/Ellipse-24.png",
        "/img/avatars/Ellipse-26.png",
        "/img/avatars/Ellipse-25.png",
        "/img/avatars/Ellipse-27.png",
        "/img/avatars/Ellipse-28.png",
        "/img/avatars/Ellipse-29.png"
      ],
      techStacks: ["Blockchain", "Data science", "Web 3"],
      recruitmentList: [
        {
          title: "Get in Touch",
          description:
            "We’ll listen to your needs and connect you to a rich pool of qualified professionals who closely match your expectations in terms of skills, qualifications, experience, seniority, salary range and cultural fit."
        },
        {
          title: "Candidate Assessments",
          description:
            "We conduct background checks including National ID, qualifications and previous employment, psychological and aptitude tests, preliminary interviews, and candidate reporting via score cards."
        },
        {
          title: "Pick Your Preferred Candidate",
          description:
            "We send you a list of the best three candidates. You conduct just three interviews and make an offer to your top choice. If any candidate rejects the offer, you can replace them with either of the other two."
        },
        {
          title: "Get Started",
          description:
            "When you find your ideal candidate, the fee is just 10% of annual salary for junior and senior roles. If the hired candidate leaves within 3 months, then we will replace the candidate with no extra cost."
        }
      ],
      outsourcingList: [
        {
          title: "Full Recruitment Service",
          description:
            "We’ll handle the entire hiring process for you as explained above."
        },
        {
          title: "Use Us as Your HR Department",
          description:
            "We’ll support you and your new team members post-hiring, doing everything an in-house HR department would do."
        },
        {
          title: "Technical Supervision",
          description:
            "A qualified Technology Analyst from Infohob will provide your staff with expert technical supervision in their specialized work. Includes a contract signed by both parties. With our highly personalized service, you can have as much or as little involvement as you like, whether you prefer to be kept in the loop at every step or simply relax knowing your people are in safe hands."
        }
      ],

      trainingList: [
        { title: "You send a request" },
        {
          title:
            "We assess your needs and design training modules to your specifications"
        },
        {
          title:
            "We assess your needs and design training modules to your specifications"
        },
        {
          title:
            "We implement the feedback to create your ideal training course"
        }
      ]
    };
  },

  computed: {
    activeList() {
      if (this.activeTab === "Recruitment") {
        return this.recruitmentList;
      } else if (this.activeTab === "Outsourcing") {
        return this.outsourcingList;
      } else if (this.activeTab === "Training") {
        return this.trainingList;
      }
    }
  },
  methods: {}
};
</script>

<style lang="scss">
.tab-list {
  min-height: 111px;
  background: #ffffff;
  border-radius: 8px;
}

.tab-list__item {
  padding: 30px 22px;
  border-bottom: 1px solid #eaecf0;
}
.tab-list__item:last-child {
  border-bottom: 0;
}

.tab-list__title {
  font-weight: 400;
  font-size: 16px;
  line-height: 28px;
  color: #101828;
}
.tab-list__description {
  font-weight: 400;
  font-size: 16px;
  line-height: 28px;
  color: #667085;
}

.c100 > span {
  color: #2c2c2c !important;
}
</style>
